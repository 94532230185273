// CardManagement.js

import React, { useEffect, useState } from 'react';
import './cardPlan.scss';
import { getUrlParam } from 'utils/url';
import { CardsType } from '../../../types';
import { getCardDetails } from 'api/actionPlan';
import { TextField } from '@mui/material';
import { logout } from 'features/auth';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { displayIcon, tabs } from 'pages/Dashboard/utilities';
import { Tab, TabsList } from 'styling/tabs';
import { formatDate } from 'pages/MessagingCentre/MessageCenter/MessageCenterComposer/utils';
import { useSystemTogglesContext } from 'context/SystemToggles';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}
const CardManagement = () => {
  let display = 0;
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={display !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      ></div>
    );
  }
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };
  const cardId = getUrlParam('cardId')?.toString() || '';
  const planId = getUrlParam('planId')?.toString() || '';
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState<CardsType | undefined>(
    undefined
  );
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  // const deleteCard = async () => {
  //   if (cardDetails)
  //     await deleteActionTask(cardDetails.id.toString())
  //       .then((response) => {
  //         if (response.success) {
  //           window.location.reload();
  //         } else {
  //           console.error('Error deleting task:', response.error);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error in task:', error);
  //       });
  // };
  const tab = '';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCardDetails(planId, cardId);
        if (result.success) {
          setCardDetails(result.card?.[0]);
        } else {
          console.log('error');
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [cardId, planId]);
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    if (newValue === 'logout') logout();
    else {
      navigate(`/dashboard/${newValue}`);
    }
  };
  if (cardDetails)
    return (
      <div className="container__dashboard-page">
        <div>
          <TabsUnstyled value={tab} onChange={handleChange}>
            <TabsList>
              {displayTabs.map((tab) => (
                <Tab
                  key={tab.link}
                  value={tab.link}
                  sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                  {...a11yProps(0)}
                >
                  <div className="dashboard-tab-icon-label" key={tab.link}>
                    {displayIcon(tab.label)} {tab.label}
                  </div>
                </Tab>
              ))}
            </TabsList>
            <TabPanel value={tab} index={0} key={0} />
            <TabPanel value={tab} index={1} key={1} />
            <TabPanel value={tab} index={2} key={2} />
            <TabPanel value={tab} index={3} key={3} />
            <TabPanel value={tab} index={4} key={4} />
            <TabPanel value={tab} index={5} key={5} />
            <TabPanel value={tab} index={6} key={6} />
          </TabsUnstyled>
        </div>

        <div>
          <div className="card-management-title">Task management</div>

          <div className="card-management-container">
            <div className="card-management-details">
              <div className="table-text">Task name</div>
              <div>
                <div className="row-card-management">
                  <div className="table-text">Task name</div>
                  <div className="table-text"> Topic</div>
                </div>

                <div className="row-card-management">
                  <TextField
                    disabled
                    value={cardDetails.title}
                    variant="standard"
                    sx={{
                      border: 'none',
                      '& .MuiInput-underline:before': { border: 'none' },
                      '& .MuiInput-underline:after': { border: 'none' },
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                      },
                      color: ' #333',
                      fontFamily: 'Gilroy',
                      marginLeft: '25px',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '120%',
                      letterSpacing: '0.18px',
                      width: '640px',
                      height: '50px',
                      textAlign: 'center',
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />{' '}
                  <div className="card-management-text">
                    {' '}
                    {cardDetails.interest?.name
                      ? cardDetails.interest?.name
                      : 'Not specified'}
                  </div>
                  <div className="divider" style={{ width: '800px' }} />
                </div>
              </div>
              <div>
                <div className="row-card-management-2">
                  <div className="table-text">Task Creator</div>
                  <div className="table-text"> Creation Date</div>
                  <div className="table-text"> Deadline</div>
                </div>
                <div className="row-card-management-2">
                  <div className="card-management-text">Me</div>
                  <div className="card-management-text">
                    {' '}
                    {formatDate(cardDetails.created)}
                  </div>
                  <div className="card-management-text">
                    {' '}
                    {formatDate(cardDetails.deadline)}
                  </div>
                </div>
                <div className="divider" style={{ width: '800px' }} />
              </div>

              <div>
                <div className="row-card-management-2">
                  <div className="table-text">Description</div>
                </div>

                <div className="card-management-text">
                  {cardDetails.description
                    ? cardDetails.description
                    : 'No description available'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <div>Loading</div>;
};

export default CardManagement;
