import React, { useEffect, useState } from 'react';
import { getUrlParam } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import useGetBoostCallEvent from 'apiHooks/boostCalls/useGetBoostCallEvent';
import { Tab, TabPanel, TabsList } from 'styling/tabs';
import { displayIcon, tabs } from 'pages/Dashboard/utilities';
import './style.scss';
import CallConfirmedPage from 'pages/Dashboard/CallConfirmedPage';
import FullScreenLoading from 'components/FullScreenLoading';
import useWindowSize from 'hooks/useWindowSize';
import { useSystemTogglesContext } from 'context/SystemToggles';

const NewCallConfirmation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();

  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);

  const tab = '';
  let navigate = useNavigate();
  const id = getUrlParam('id') as string;

  const { boostCallEvent } = useGetBoostCallEvent({
    id: id,
  });
  const { toggles } = useSystemTogglesContext();
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return boostCallEvent ? (
    <>
      {isMobile ? (
        <div className="dashboard-container-info">
          <CallConfirmedPage event={boostCallEvent} />
        </div>
      ) : (
        <div className="container__dashboard-page">
          <div>
            <TabsUnstyled
              value={tab}
              onChange={(event: React.SyntheticEvent, newValue: any) =>
                navigate(`/dashboard/${newValue}`)
              }
            >
              <TabsList>
                {displayTabs.map((tab: any) => (
                  <Tab
                    key={tab.link}
                    value={tab.link}
                    sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                    {...a11yProps(0)}
                  >
                    <div className="dashboard-tab-icon-label" key={tab.link}>
                      {displayIcon(tab.label)} {tab.label}
                    </div>
                  </Tab>
                ))}
              </TabsList>
              <TabPanel value={tab} key={0} />
              <TabPanel value={tab} key={1} />
              <TabPanel value={tab} key={2} />
              <TabPanel value={tab} key={3} />
              <TabPanel value={tab} key={4} />
              <TabPanel value={tab} key={5} />
            </TabsUnstyled>
          </div>
          <div className="dashboard-container-info">
            <CallConfirmedPage event={boostCallEvent} />
          </div>
        </div>
      )}
    </>
  ) : (
    <FullScreenLoading />
  );
};

export default NewCallConfirmation;
