import React, { memo, useRef, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import RecommendationsCard from './RecommendationsCard';
import RecMentorCard from './RecMentorCard';

import './style.scss';

type Props = {
  events: Array<any>;
  mentors: Array<any>;
  timeZone: string;
  hideGroupEvents: boolean;
  refetchRecommendedData: any;
};

const RecommendationsSection = ({
  events,
  mentors,
  hideGroupEvents,
  timeZone,
  refetchRecommendedData,
}: Props) => {
  const sliderMentorsRef = useRef<any>(null);

  const settingsMentors = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [mentorsIndex, setMentorsIndex] = useState(0);

  const handleMentorsNext = () => {
    if (mentorsIndex < mentors.length - 2) {
      sliderMentorsRef.current?.slickNext();
      setMentorsIndex(mentorsIndex + 1);
    }
  };

  const handleMentorsPrev = () => {
    if (mentorsIndex > 0) {
      sliderMentorsRef.current?.slickPrev();
      setMentorsIndex(mentorsIndex - 1);
    }
  };
  const mentorsPreviousDisabled = mentorsIndex === 0;
  const mentorsNextDisabled = mentorsIndex === mentors.length - 2;

  return (
    <div className="recommendations-section__container">
      <div className="recommendations-section__title-container recommendations-section__margin">
        {hideGroupEvents === false && events.length > 0 ? (
          <div className="recommendations-section__title">Events</div>
        ) : null}
      </div>
      {hideGroupEvents === false && events.length > 0 ? (
        <div className="recommended-event-card">
          {' '}
          {events?.slice(0, 3)?.map((event, idx) => (
            <RecommendationsCard
              key={idx}
              title={event?.title || event.interest?.name}
              type={event?.type}
              volunteerImage={event?.volunteer?.profile_picture}
              volunteerName={event?.volunteer?.first_name}
              startTime={event?.start_time}
              timeZone={timeZone}
              code={event?.code}
            />
          ))}
        </div>
      ) : null}
      {mentors.length > 0 ? (
        <div>
          <div className="recommendations-section__title-container">
            <div className="recommendations-section__title">Mentors</div>
            {mentors.length > 1 ? (
              <div>
                <img
                  src={
                    mentorsPreviousDisabled
                      ? '/arrow-left-inactive.svg'
                      : '/arrow-left-active.svg'
                  }
                  onClick={handleMentorsPrev}
                  alt="left arrow button"
                />
                <img
                  src={
                    mentorsNextDisabled
                      ? '/arrow-right-inactive.svg'
                      : '/arrow-right-active.svg'
                  }
                  onClick={handleMentorsNext}
                  alt="right arrow button"
                />
              </div>
            ) : null}
          </div>

          <div className="recommendations-section-slider-relative">
            <div className="recommendations-section-slider-absolute">
              <Slider {...settingsMentors} ref={sliderMentorsRef}>
                {mentors.length > 0 ? (
                  mentors.map((mentor, idx) => (
                    <RecMentorCard
                      key={idx}
                      mentor={mentor}
                      refetchRecommendedData={refetchRecommendedData}
                    />
                  ))
                ) : (
                  <div className="impact-help-label">
                    We will recommend mentors when you set your skills
                    preferences
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(RecommendationsSection);
