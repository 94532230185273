import React, { useState, useCallback, memo } from 'react';
import Input from '@mui/material/Input';
import './style.scss';
import { MessagingSendButton } from 'styling/buttons';
import AttachmentPopup from './AttachmentPopup';
import MiniLibrary from './MiniLibrary';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { sendAttachmentFromLibrary } from 'api/messages';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sendAttachmentInMessage } from 'api/messages';
import useSelectProfileData from 'hooks/useSelectProfileData';
import LoadingSplashPopup from 'pages/LoadingSplash';
interface MessageCenterComposerProps {
  sendMessage: (msg: string) => void;
  isMobile: boolean;
  refetchMessage: () => void;
  volunteerId: number | undefined;
}
type FileItem = {
  fileId: number;
  fileName: string;
};
const MessageCenterComposer = ({
  sendMessage,
  isMobile,
  refetchMessage,
  volunteerId,
}: MessageCenterComposerProps) => {
  const [value, setValue] = useState('');
  const [openAttachmentPopup, setOpenAttachmentPopup] =
    useState<boolean>(false);
  const [openLibrary, setOpenLibrary] = useState<boolean>(false);
  const { toggles } = useSystemTogglesContext();
  const showAttachmentCenter = toggles['ATTACHMENT_CENTRE'];
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [filesToSend, setFilesToSend] = useState<FileItem[]>([]);
  const calendlyErrorMessage =
    'Oops! It looks like you might be sharing an off-platform booking link - please book your mentoring sessions through our platform.';
  const [uploadFilesToSend, setUploadFilesToSend] = useState<
    FileList | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const sendFilesFromLibrary = (file: FileItem) => {
    setFilesToSend((prevFiles) => {
      if (!prevFiles.some((f) => f.fileId === file.fileId)) {
        if (prevFiles.length < 5) {
          return [...prevFiles, file];
        }
      }
      return prevFiles;
    });
  };
  const handleRemoveFile = (fileId: number) => {
    setFilesToSend(filesToSend.filter((file) => file.fileId !== fileId));
  };
  const handleRemoveFileUpload = (fileName: string) => {
    setUploadFilesToSend(undefined);
  };
  const handleChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value;
    if (newValue.toLowerCase().includes('calendly')) {
      setErrorMessage(calendlyErrorMessage);
    } else {
      setErrorMessage('');
    }
    setValue(newValue);
  };
  const handleSendAttachmentFromLibrary = useCallback(
    async (files) => {
      for (var i = 0; i < files.length; i++)
        try {
          const result = await sendAttachmentFromLibrary(
            [files[i]],
            volunteerId
          );

          if (result.success) {
            refetchMessage();
            setOpenAttachmentPopup(false);
          } else {
            if (result.error.includes('Not enough space'))
              setErrorMessage(
                'Oops, your attachment couldn’t be sent as the recipient has no storage left. You can still send messages to ask them to clear some space.'
              );
            else setErrorMessage(result.error);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
    },
    [refetchMessage, volunteerId]
  );

  const { organisationProfile } = useSelectProfileData({ autoFetch: true });

  const uploadFiles = (files: FileList) => {
    setUploadFilesToSend(files);
  };

  function countFiles(fileList: FileList | undefined) {
    let count = 0;
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        count++;
      }
      return count;
    } else return 0;
  }

  const FileListDisplay = (uploadFilesToSend: FileList) => {
    const fileItems = [];

    for (let i = 0; i < uploadFilesToSend.length; i++) {
      const file = uploadFilesToSend[i];
      fileItems.push(
        <div key={file.name} className="file-item">
          <img
            height={'14px'}
            alt="attachment-icon"
            src="/attachment_message.svg"
          />{' '}
          {file.name}
          <IconButton
            aria-label="close"
            onClick={() => handleRemoveFileUpload(file.name)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    return <>{fileItems}</>;
  };
  const handleSendMessage = useCallback(() => {
    setErrorMessage('');
    const handleFileUpload = async (selectedFiles: FileList) => {
      setLoading(true);
      try {
        const result = await sendAttachmentInMessage(
          selectedFiles,
          organisationProfile?.id,
          volunteerId
        );
        if (result.success) {
          refetchMessage();
          setUploadFilesToSend(undefined);
        } else {
          if (result.error.includes('Not enough space'))
            setErrorMessage(
              'Oops, your attachment couldn’t be sent as the recipient has no storage left. You can still send messages to ask them to clear some space.'
            );
          else setErrorMessage(result.error);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    };
    if (value) sendMessage && sendMessage(value);
    if (filesToSend.length > 0) {
      const fileIds = filesToSend.map((file) => file.fileId);
      handleSendAttachmentFromLibrary(fileIds);
    }

    if (uploadFilesToSend) {
      handleFileUpload(uploadFilesToSend);
    }
    setValue('');
    setFilesToSend([]);
    setUploadFilesToSend(undefined);
  }, [
    sendMessage,
    value,
    filesToSend,
    handleSendAttachmentFromLibrary,
    uploadFilesToSend,
    organisationProfile?.id,
    refetchMessage,
    volunteerId,
  ]);
  const handleKeydown = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        value.length > 0 &&
        !event.shiftKey &&
        !isMobile &&
        !errorMessage?.includes(calendlyErrorMessage)
      ) {
        handleSendMessage();
        event.preventDefault();
      } else if (
        event.key === 'Enter' &&
        uploadFilesToSend &&
        uploadFilesToSend?.length > 0 &&
        !isMobile
      ) {
        handleSendMessage();
        event.preventDefault();
      } else if (
        event.key === 'Enter' &&
        filesToSend &&
        filesToSend?.length > 0 &&
        !isMobile
      ) {
        handleSendMessage();
        event.preventDefault();
      }
    },
    [
      handleSendMessage,
      value.length,
      isMobile,
      filesToSend,
      uploadFilesToSend,
      errorMessage,
    ]
  );
  if (loading)
    return (
      <LoadingSplashPopup open={loading} message="Your message is being sent" />
    );
  return (
    <div className="message-center-composer-container">
      {showAttachmentCenter ? (
        <div className="message-center-composer-attachment-container">
          <img
            style={{ cursor: 'pointer' }}
            alt="send attachment in conversation button"
            onClick={() => setOpenAttachmentPopup(true)}
            src="/attachment_message.svg"
          />
        </div>
      ) : null}
      <div className="message-center-composer-wrapper">
        <Input
          placeholder="Type your message here"
          value={value}
          multiline
          maxRows={10}
          onChange={handleChange}
          onKeyDown={handleKeydown}
          fullWidth
        />
        <div className="files-list">
          {filesToSend.map((file) => (
            <div key={file.fileId} className="file-item">
              <img
                height={'14px'}
                alt="attachment-icon"
                src="/attachment_message.svg"
              />{' '}
              {file.fileName}
              <IconButton
                aria-label="close"
                onClick={() => handleRemoveFile(file.fileId)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
          {uploadFilesToSend && FileListDisplay(uploadFilesToSend)}
        </div>
        {errorMessage ? (
          <div className="error-message-attachment">{errorMessage}</div>
        ) : undefined}
        <div className="message-center-composer-break-text">
          Shift &#x21E7; + Enter &#x23CE; to make a line break
        </div>
      </div>
      <MessagingSendButton
        id="enterButton"
        disabled={
          (value.length < 1 &&
            filesToSend.length < 1 &&
            uploadFilesToSend === undefined) ||
          errorMessage?.includes(calendlyErrorMessage)
        }
        onClick={handleSendMessage}
      >
        <img
          src={
            value.length < 1 && filesToSend.length < 1
              ? 'send.svg'
              : 'send-active.svg'
          }
          alt="send button"
        />
      </MessagingSendButton>
      <AttachmentPopup
        count={filesToSend.length + countFiles(uploadFilesToSend)}
        refetchMessage={refetchMessage}
        open={openAttachmentPopup}
        setOpenAttachmentPopup={setOpenAttachmentPopup}
        setOpenLibrary={setOpenLibrary}
        volunteerId={volunteerId}
        uploadFileToSend={uploadFiles}
      />
      <MiniLibrary
        open={openLibrary}
        setOpenLibrary={setOpenLibrary}
        sendFilesFromLibrary={sendFilesFromLibrary}
      />
    </div>
  );
};

export default memo(MessageCenterComposer);
