import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageComponent from '../MessageComponent';

import './style.scss';

interface MessagesContainerProps {
  messages?: any;
  messagesTotal: number;
  handleGetNextMessages: () => void;
  hasNextMessages: boolean;
}

const MessagesContainer = ({
  messages,
  messagesTotal,
  handleGetNextMessages,
  hasNextMessages,
}: MessagesContainerProps) => (
  <div id="scrollableDiv" className="message-center-container-wrapper">
    <InfiniteScroll
      dataLength={messagesTotal}
      next={handleGetNextMessages}
      hasMore={hasNextMessages}
      loader={<h4>Loading...</h4>}
      inverse={true}
      scrollableTarget="scrollableDiv"
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
    >
      {messages?.map((msg: any, idx: number) => (
        <div className="message-center-container-fragment" key={idx}>
          {msg?.messages?.map((message: any, i: number) => (
            <MessageComponent key={i} message={message} />
          ))}

          <div className="message-center-container-date-wrapper">
            <div className="message-center-container-date">{msg.date}</div>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  </div>
);

export default MessagesContainer;
