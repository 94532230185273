import { useEffect } from 'react';
import Cookie from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import useRedux from '../../../hooks/useRedux';
import {
  loginAction,
  signupAction,
  logout as logoutAction,
  setAccessToken as setAccessTokenAction,
  setRefreshToken as setRefreshTokenAction,
  selectIsLoadingAuth,
  selectAuthError,
  selectAccessToken,
  selectRefreshToken,
  AuthActionProps,
} from '../index';
import useSelectProfileData from '../../../hooks/useSelectProfileData';
import {
  getAccessTokenCookie,
  getRefreshTokenCookie,
} from '../../../utils/auth';
import { getUrlParam } from '../../../utils/url';
import { RESET_STORE } from 'store';

type Props = { autoFetch: boolean };

const useAuth = ({ autoFetch = true }: Props = { autoFetch: true }) => {
  // HOOKS

  const { useSelector, dispatch } = useRedux();
  const { fetchAllProfileData } = useSelectProfileData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirectPathEncoded = getUrlParam('redirect') as string;
  const redirectPath = redirectPathEncoded && decodeURI(redirectPathEncoded);
  const defaultRedirect =
    pathname === '/select-availability'
      ? '/select-availability'
      : '/dashboard/actions';

  // ACTIONS
  const login = async ({ email, password }: AuthActionProps) => {
    const loginResult = await dispatch(loginAction({ email, password }));

    if (!loginResult || loginResult.type !== 'auth/login/fulfilled') {
      return;
    }

    const profileFetchResult = await fetchAllProfileData();
    const draftRequest = Cookie.get('draftBoostCallUUID');

    const needsToFinishSignup =
      profileFetchResult.orgProfileResult.status === 'rejected' ||
      (profileFetchResult.orgProfileResult.status === 'fulfilled' &&
        !profileFetchResult.orgProfileResult.value.payload);
    if (needsToFinishSignup) {
      navigate(`/add-details`);
    } else if (!!redirectPath) {
      const currentDomain = window.location.origin;
      const newURL = currentDomain + redirectPath;
      window.location.href = newURL;
    } else if (draftRequest) {
      navigate(`/mentor-select`);
    } else {
      navigate(defaultRedirect);
    }
  };

  const signup = async ({
    email,
    password,
    partner = null,
    signup_options = [],
  }: AuthActionProps) => {
    const result = await dispatch(
      signupAction({ email, password, partner, signup_options })
    );
    return result;
  };
  const resetStore = () => RESET_STORE();

  const logout = async (redirectPath?: string) => {
    Cookie.remove('loggedIn');
    Cookie.remove('accessOrgTokenDB');
    Cookie.remove('refreshOrgTokenDB');
    Cookie.remove('draftBoostCallUUID');
    Cookie.remove('partner_id');
    dispatch(resetStore());
    await dispatch(logoutAction());
    navigate('/login');
  };

  // SELECTORS
  const isLoadingAuth = useSelector(selectIsLoadingAuth);
  const authError = useSelector(selectAuthError);
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  const accessTokenCookie = getAccessTokenCookie();
  const refreshTokenCookie = getRefreshTokenCookie();

  // KEEP TOKENS IN STATE
  useEffect(() => {
    if (accessTokenCookie && !accessToken) {
      dispatch(setAccessTokenAction(accessTokenCookie));
    }
  }, [accessToken, accessTokenCookie, dispatch]);

  useEffect(() => {
    if (refreshTokenCookie && !refreshToken) {
      dispatch(setRefreshTokenAction(refreshTokenCookie));
    }
  }, [dispatch, refreshToken, refreshTokenCookie]);

  return {
    login,
    signup,
    logout,
    isLoadingAuth,
    authError,
    accessToken,
    refreshToken,
  };
};

export default useAuth;
