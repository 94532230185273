import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
  StyledTrashButton,
} from 'styling/buttons';
import AddIcon from '@mui/icons-material/Add';
import './actionPlan.scss';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ActionPlanType } from 'types';
import PageNotFound from 'pages/PageNotFound';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useSystemTogglesContext } from 'context/SystemToggles';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import {
  createActionPlan,
  deleteActionPlan,
  getActionPlansPagination,
} from 'api/actionPlan';
import { formatDate } from 'pages/MessagingCentre/MessageCenter/MessageCenterComposer/utils';
import DeletePlanPopup from './deletePlanPopup';
import MentorsPopup from './mentorsPopup';
import useWindowSize from 'hooks/useWindowSize';
import { CustomTextFieldActionPlanName } from 'styling/generalStyling';
import { getAccessTokenCookie } from 'utils/auth';

const ActionPlansDashboard = () => {
  const loggedInUser = !!getAccessTokenCookie();
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  const navigate = useNavigate();
  const [deletePlanPopup, setDeletePlanPopup] = useState(false);
  const [createNewPlan, setCreateNewPlan] = useState<boolean>(false);
  const [actionPlanName, setActionPlanName] = useState('');
  const [numberActionPlans, setNumberActionPlans] = useState(-1);
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setActionPlanName(event.target.value);
  };
  const queryParams = window.location.search;
  const [counter, setCounter] = useState<number>(10);
  const [changes, setChanges] = useState<number>(0);
  const setCounterChange = () => {
    setChanges(changes + 1);
  };
  const nextUrlRef = useRef(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const createPlan = async () => {
    if (actionPlanName.length < 1) {
      // set error message to the user
      setErrorMessage('Please add a plan name');
    } else
      try {
        const result = await createActionPlan(actionPlanName);

        if (result.plan) navigate(`/plan?planId=${result.plan.id}`);
      } catch (error) {
        console.error('Error creating action plan:', error);
      }
  };
  const [deletePlanId, setDeletePlanId] = useState<string | null>(null);
  const openPlan = (planName: string) => {
    navigate(`/plan?planId=${planName}`);
  };
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [planIdSelected, setPlanIdSelected] = useState<number | null>(null);
  const [actionPlans, setActionPlans] = useState<ActionPlanType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const openSharePlan = (planId: number) => {
    setPlanIdSelected(planId);
    setOpenSharePopup(true);
  };

  const deletePlan = async (id: string | null) => {
    if (id)
      await deleteActionPlan(id)
        .then((response) => {
          if (response.success) {
            setActionPlans([]);
            setCounterChange();
            setCounter(10);
            setDeletePlanPopup(false);
          } else {
            console.error('Error deleting action plan:', response.error);
          }
        })
        .catch((error) => {
          console.error('Error in deletePlan:', error);
        });
    else console.error('Error in deletePlan:');
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await getActionPlansPagination(
          counter,
          nextUrlRef.current
        );
        if (result.success) {
          setNumberActionPlans(result.pagination?.totalRecords);
          setActionPlans((prevPlans) => [...prevPlans, ...result.plans]);
          nextUrlRef.current = result.pagination?.nextUrl;
        } else {
          console.log('Error fetching data');
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [counter, changes]);

  const loadMore = () => {
    setCounter((prevCounter) => prevCounter + 10);
  };
  if (isLoading)
    return (
      <div className="center-in-page">
        <img
          width="75px"
          height="75px"
          src={'/loading-icon.svg'}
          alt="loading-icon"
        />
      </div>
    );

  if (showActionPlan)
    if (isMobile)
      return (
        <div className="actionplan-container-mobile">
          <div className="actionplan-topbar-mobile">
            <div className="actionplan-title-page-mobile">
              My Action Plans{' '}
              <a
                href="https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/New+Features/Action+Plan/Org+Action+Plan+Platform+video.mp4"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={'/video-tutorial.svg'}
                  alt="video tutorial"
                  style={{ marginLeft: '10px' }}
                />
              </a>
            </div>
            {loggedInUser ? (
              <DashboardEmptyButton
                variant="outlined"
                sx={{
                  width: '290px',
                  padding: ' 16px 36px',
                  fontSize: '17px',
                  fontWeight: '700',
                  lineHeight: '120%',
                  letterSpacing: '0.17px',
                  borderRadius: '32px',
                  border: '2px solid #583372',
                }}
                onClick={() => setCreateNewPlan(true)}
              >
                <AddIcon /> Add new plan
              </DashboardEmptyButton>
            ) : (
              <DashboardEmptyButton
                variant="outlined"
                sx={{
                  width: '290px',
                  padding: ' 16px 36px',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: '120%',
                  letterSpacing: '0.17px',
                  borderRadius: '32px',
                  border: '2px solid #583372',
                }}
                onClick={() => navigate(`/signup${queryParams}`)}
              >
                <AddIcon /> Sign up to create a new plan
              </DashboardEmptyButton>
            )}
          </div>
          {createNewPlan ? (
            <div
              className="plan-overview-mobile"
              style={{
                marginBottom: '20px',
                marginTop: '20px',
                height: 'auto',
              }}
            >
              <div style={{ cursor: 'pointer' }}>
                <CustomTextFieldActionPlanName
                  value={actionPlanName}
                  onChange={handleChange}
                  variant="standard"
                  multiline
                  label={'Type the name of your action plan here'}
                  sx={{
                    width: '100%',

                    paddingLeft: '10px',
                    paddingRIght: '10px',
                    fontFamily: 'Gilroy',
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </div>
              <div className="divider" />

              <div
                className="plan-overview-functions-mobile"
                style={{
                  display: 'inline-flex',
                  cursor: 'pointer',
                  height: '50px',
                  justifyContent: 'flex-end',
                }}
                onClick={createPlan}
              >
                Create action plan <ArrowCircleRightOutlinedIcon />
              </div>
              {errorMessage ? (
                <div
                  className="error-message"
                  style={{
                    paddingRight: '20px',
                    display: 'inline-flex',
                    cursor: 'pointer',
                    justifyContent: 'flex-end',
                  }}
                >
                  {errorMessage}
                </div>
              ) : null}
            </div>
          ) : null}
          {actionPlans.length === 0 && !createNewPlan ? (
            <div className="actionplan-container-mobile">
              <p
                style={{
                  fontWeight: '600',
                  fontSize: '18px',
                  marginTop: '20px',
                }}
              >
                {' '}
                Here you will turn your ideas and thoughts into action plans to
                grow your business!
              </p>
              <p>Watch the video to get started 💪</p>
              <video id="tutorial-action-plan" controls width="300px">
                <source
                  src="https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/New+Features/Action+Plan/Org+Action+Plan+Platform+video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          ) : (
            <div className="plan-overview-container-mobile">
              {actionPlans.map((plan: ActionPlanType) => (
                <div className="plan-overview-mobile" key={plan.id}>
                  <div className="plan-overview-title-mobile">
                    <div
                      onClick={() => openPlan(plan.id)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {plan.name.length > 30
                        ? `${plan.name.slice(0, 30)}...`
                        : plan.name}
                    </div>
                    {plan.my_permission === 'owner' ? (
                      <StyledTrashButton
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setDeletePlanPopup(true);
                          setDeletePlanId(plan.id);
                        }}
                      />
                    ) : (
                      <BlockOutlinedIcon />
                    )}
                  </div>
                  <div className="divider" />
                  <div className="plan-overview-functions-mobile">
                    <div
                      className="plan-overview-date-mobile"
                      onClick={() => openPlan(plan.id)}
                      style={{
                        fontSize: '14px',

                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <CalendarMonthOutlinedIcon />
                      {formatDate(plan.created)}
                    </div>

                    {plan.volunteer ? (
                      <div>
                        {' '}
                        {plan.volunteer.first_name.length > 10
                          ? `${plan.volunteer.first_name.slice(0, 10)}...`
                          : plan.volunteer.first_name}
                      </div>
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => openSharePlan(parseInt(plan.id))}
                      />
                    )}
                  </div>
                </div>
              ))}
              {numberActionPlans > counter ? (
                <DashboardFilledButton
                  variant="contained"
                  onClick={loadMore}
                  sx={{ width: '200px', alignSelf: 'center' }}
                >
                  Load more
                </DashboardFilledButton>
              ) : null}
              {openSharePopup && planIdSelected ? (
                <MentorsPopup
                  planId={planIdSelected}
                  open={openSharePopup}
                  handleClose={() => setOpenSharePopup(false)}
                />
              ) : null}
              {deletePlanPopup ? (
                <DeletePlanPopup
                  open={deletePlanPopup}
                  onClose={() => setDeletePlanPopup(false)}
                  handleDeleteSelectedPlan={() => deletePlan(deletePlanId)}
                  deletePlan={undefined}
                />
              ) : null}
            </div>
          )}
        </div>
      );
    else
      return (
        <div className="actionplan-container">
          <div className="actionplan-topbar">
            <div className="actionplan-title-page">
              My Action Plans{' '}
              <a
                href="https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/New+Features/Action+Plan/Org+Action+Plan+Platform+video.mp4"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={'/video-tutorial.svg'}
                  style={{ marginLeft: '10px' }}
                  alt="video tutorial"
                />
              </a>
            </div>

            {!createNewPlan ? (
              <div>
                {loggedInUser ? (
                  <DashboardEmptyButton
                    variant="outlined"
                    sx={{
                      width: '290px',
                      padding: ' 16px 36px',
                      fontSize: '17px',
                      fontWeight: '700',
                      lineHeight: '120%',
                      letterSpacing: '0.17px',
                      borderRadius: '32px',
                      border: '2px solid #583372',
                    }}
                    onClick={() => setCreateNewPlan(true)}
                  >
                    <AddIcon /> Add new plan
                  </DashboardEmptyButton>
                ) : (
                  <DashboardEmptyButton
                    variant="outlined"
                    sx={{
                      width: '290px',
                      padding: ' 16px 36px',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '120%',
                      letterSpacing: '0.17px',
                      borderRadius: '32px',
                      border: '2px solid #583372',
                    }}
                    onClick={() => navigate(`/signup${queryParams}`)}
                  >
                    <AddIcon /> Sign up to create a new plan
                  </DashboardEmptyButton>
                )}
              </div>
            ) : null}
          </div>
          {createNewPlan ? (
            <div>
              <div
                className="plan-overview"
                style={{
                  marginTop: '40px',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    marginLeft: '20px',
                  }}
                >
                  <CustomTextFieldActionPlanName
                    value={actionPlanName}
                    onChange={handleChange}
                    id="standard-basic"
                    label={'Type the name of your new action plan here'}
                    variant="standard"
                    sx={{
                      width: '100%',
                      fontFamily: 'Gilroy',
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>

                <div
                  className="plan-overview-functions"
                  onClick={createPlan}
                  style={{
                    cursor: 'pointer',
                    width: '500px',
                    justifyContent: 'space-between',
                    marginRight: '50px',
                  }}
                >
                  <div className="vertical-divider" />
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      columnGap: '10px',
                    }}
                  >
                    Create action plan <ArrowCircleRightOutlinedIcon />
                  </div>
                </div>
              </div>
              {errorMessage ? (
                <div
                  className="error-message"
                  style={{
                    display: 'inline-flex',
                    width: '950px',
                    justifyContent: 'flex-end',
                  }}
                >
                  {errorMessage}
                </div>
              ) : null}
            </div>
          ) : null}

          {actionPlans.length === 0 && !createNewPlan ? (
            <div>
              <p
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  marginTop: '30px',
                }}
              >
                {' '}
                Here you will turn your ideas and thoughts into action plans to
                grow your business!
              </p>
              <p>Watch the video to get started 💪</p>
              <video id="tutorial-action-plan" controls width="100%">
                <source
                  src="https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/New+Features/Action+Plan/Org+Action+Plan+Platform+video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          ) : (
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ul style={{ padding: 0 }}>
                {actionPlans.map((plan: ActionPlanType) => (
                  <div
                    className="plan-overview"
                    key={plan.id}
                    style={{ marginBottom: '20px' }}
                  >
                    <div
                      className="plan-overview-title"
                      onClick={() => openPlan(plan.id)}
                    >
                      {' '}
                      {plan.name}
                    </div>
                    <div className="plan-overview-functions">
                      <div className="vertical-divider" />
                      <CalendarMonthOutlinedIcon />
                      <div className="action-plan-date">
                        {formatDate(plan.created)}
                      </div>
                      <div className="vertical-divider" />
                      {plan.volunteer ? (
                        <div
                          style={{
                            width: '80px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {plan.volunteer.first_name.length > 10
                            ? `${plan.volunteer.first_name.slice(0, 10)}...`
                            : plan.volunteer.first_name}
                        </div>
                      ) : (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: 'pointer', width: '80px' }}
                          onClick={() => openSharePlan(parseInt(plan.id))}
                        />
                      )}

                      <div className="vertical-divider" />
                      {plan.my_permission === 'owner' ? (
                        <StyledTrashButton
                          style={{
                            cursor: 'pointer',
                            width: '25px',
                            height: '25px',
                          }}
                          onClick={() => {
                            setDeletePlanPopup(true);
                            setDeletePlanId(plan.id);
                          }}
                        />
                      ) : (
                        <BlockOutlinedIcon />
                      )}
                    </div>
                  </div>
                ))}
              </ul>
              {numberActionPlans > counter ? (
                <DashboardFilledButton
                  variant="contained"
                  onClick={loadMore}
                  sx={{ width: '300px', alignSelf: 'center' }}
                >
                  Load more
                </DashboardFilledButton>
              ) : null}
            </div>
          )}
          {openSharePopup && planIdSelected ? (
            <MentorsPopup
              planId={planIdSelected}
              open={openSharePopup}
              handleClose={() => setOpenSharePopup(false)}
            />
          ) : null}
          {deletePlanPopup ? (
            <DeletePlanPopup
              open={deletePlanPopup}
              onClose={() => setDeletePlanPopup(false)}
              handleDeleteSelectedPlan={() => deletePlan(deletePlanId)}
              deletePlan={undefined}
            />
          ) : null}
        </div>
      );
  else return <PageNotFound />;
};

export default ActionPlansDashboard;
