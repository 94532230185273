export const toTitleCase = (s: string): string =>
  s.replace(/^(.)|\s+(.)/g, (s: any, c: any, d: any) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );

export const replaceUnderscoreWithSpaces = (s: string): string =>
  s.replace(/_/g, ' ');

export const removeWhiteSpace = (inputString: string) => {
  // Use a regular expression to replace all white spaces with an empty string
  return inputString.replace(/\s/g, '');
};
export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const roundToNextWhole = (num: number) => {
  return Math.ceil(num);
};
