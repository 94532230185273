import { useAxios } from '../../api/config';
import { getErrorMessageFromResponse } from '../../utils/error';

type UpdatePasswordProps = {
  code: string;
  password: string;
  token: string;
};

const useUpdatePassword = () => {
  const [{ data, loading, error }, executeUpdatePassword] = useAxios(
    {
      url: `api/core/resetpassword/`,
      method: 'POST',
      headers: {},
    },
    { manual: true }
  );

  const typedUpdatePassword = async ({
    code,
    password,
    token,
  }: UpdatePasswordProps) => {
    const updatePasswordResponse = await executeUpdatePassword({
      data: {
        code: code,
        password: password,
        token: token,
      },
    });
    return updatePasswordResponse;
  };

  return {
    updatePassword: typedUpdatePassword,
    updatePasswordResponseData: data,
    isLoadingUpdatePassword: loading,
    updatePasswordError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useUpdatePassword;
