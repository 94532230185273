import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import CloseIcon from '@mui/icons-material/Close';
import { Tab, TabPanel, TabsList } from '../../styling/tabs';

import useWindowSize from '../../hooks/useWindowSize';
import './style.scss';
import MessagePreview from './MessagePreview';
import UnselectedMessage from './UnselectedMessage';
import MessageCenter from './MessageCenter';
import NoMessages from './NoMessages';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import NewMessageModal from './NewMessageModal';
import BlockMessageModal from './BlockMessageModal';
import useMessagingCentre from '../../apiHooks/messagingCentre/useMessagingCentre';
import { setMessageUsers } from '../../apiHooks/messagingCentre';
import useRedux from '../../hooks/useRedux';
import _ from 'lodash';
import { bookingsBadge, displayIcon, tabs } from 'pages/Dashboard/utilities';
import { getUrlParam } from 'utils/url';
import FullScreenLoading from 'components/FullScreenLoading';
import useSelectProfileData from 'hooks/useSelectProfileData';
import InfiniteScroll from 'react-infinite-scroll-component';
import useGetBoostCallEventsPending from 'apiHooks/boostCalls/useGetBoostCallEventsPending';
import useGetBoostCallEventsScheduled from 'apiHooks/boostCalls/useGetBoostCallEventsScheduled';
import useGetGroupEvents from 'apiHooks/groupEvents/useGetGroupEvents';
import { BoostCallEventWithVolunteerProfile, GroupEvent } from 'types';

const MessagingCentre = () => {
  const { dispatch } = useRedux();
  const tab = '';
  let navigate = useNavigate();

  const { organisationProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();

  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  useEffect(() => {
    if (toggles['NEW_MESSAGING_UI'] && !showMessagingUI) {
      navigate(`/dashboard/actions`);
    }
  }, [navigate, showMessagingUI, toggles]);

  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth, height } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width <= 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [newMessageModal, setNewMessageModal] = useState(false);
  const [blockMessageModal, setBlockMessageModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [messageScreen, setMessageScreen] = useState('preview');
  const [page, setPage] = useState(1);
  const [pageUsers, setPageUsers] = useState(1);
  const [pageNewUsers, setPageNewUsers] = useState(1);

  const volunteerIdUrl = getUrlParam('id') as string;
  const hasVolunteerIdUrl = !!volunteerIdUrl;

  const {
    searchMessages,
    messages: messagingCentreMessages,
    messagesTotal,
    hasNextMessages,
    sendMessage,
    messageUsers,
    hasNextUsers,
    isLoadingMessageUsers,
    blockUser,
    availableUsers,
    hasNextAvailableUsers,
    isLoadingAvailableUsers,
    getMessageUsers,
    getAvailableUsers,
    readMessages,
  } = useMessagingCentre({ hasVolunteerIdUrl });
  const { boostCallEventsScheduled } = useGetBoostCallEventsScheduled();
  const { boostCallEventsPending } = useGetBoostCallEventsPending();
  const { groupEventResultsData: myGroupEvents } = useGetGroupEvents({});

  const groupEventsShow = toggles['SHOW_GROUP_EVENTS'] ? myGroupEvents : [];

  const upcomingGroupEvents = groupEventsShow.filter(
    (event) => event.status === 'scheduled'
  );

  const upcomingCallsAndGroupEvents = [
    ...upcomingGroupEvents,
    ...boostCallEventsScheduled,
  ].sort(
    (
      a: BoostCallEventWithVolunteerProfile | GroupEvent,
      b: BoostCallEventWithVolunteerProfile | GroupEvent
    ) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );

  useEffect(() => {
    async function readUserMessages(user: any) {
      await readMessages(user?.volunteer?.id);
      await getMessageUsers();
    }

    if (isFirstLoading) {
      if (
        (messageUsers?.length > 0 || availableUsers?.length > 0) &&
        hasVolunteerIdUrl
      ) {
        const user = messageUsers?.filter(
          (user: any) =>
            parseInt(user?.volunteer?.id) === parseInt(volunteerIdUrl)
        )?.[0];

        if (user) {
          searchMessages(user?.volunteer?.id);
          setSelectedUser(user);
          setIsFirstLoading(false);
          if (user?.organisation_new_messages_count > 0) {
            readUserMessages(user);
          }
        } else {
          const newUser = availableUsers?.filter(
            (user: any) =>
              parseInt(user?.volunteer?.id) === parseInt(volunteerIdUrl)
          )?.[0];

          if (newUser) {
            handleAddMessageUser(newUser);
            setIsFirstLoading(false);
          }
        }

        if (isMobile) {
          setMessageScreen('message');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageUsers, availableUsers, hasVolunteerIdUrl, volunteerIdUrl]);

  useEffect(() => {
    return () => {
      getMessageUsers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useRef(
    _.debounce((value) => getMessageUsers(value), 500)
  ).current;

  const handleSendMessage = async (msg: string) => {
    await sendMessage(selectedUser?.volunteer?.id, msg);
    await searchMessages(selectedUser?.volunteer?.id, page);
  };
  const refetchMessage = async () => {
    await searchMessages(selectedUser?.volunteer?.id, page);
  };

  const handleClickBlock = async () => {
    const isBlocked = selectedUser?.is_organisation_active;

    await blockUser(selectedUser?.volunteer?.id, !isBlocked);
    await getMessageUsers();

    const updatedUser = Object.assign({}, selectedUser);
    updatedUser.is_organisation_active = !updatedUser.is_organisation_active;
    setSelectedUser(updatedUser);
  };

  const handleAddMessageUser = async (user: any) => {
    if (
      user?.has_messages ||
      messageUsers?.filter(
        (existingUser: any) =>
          existingUser?.volunteer?.id === user?.volunteer?.id
      )?.length > 0
    ) {
      if (
        messageUsers?.filter(
          (existingUser: any) =>
            existingUser?.volunteer?.id === user?.volunteer?.id
        )?.length < 1
      ) {
        const newUser = Object.assign({}, user);
        const newArray = [newUser, ...messageUsers];
        dispatch(setMessageUsers(newArray));
      }

      setPage(1);
      searchMessages(user?.volunteer?.id, 1);
      setSelectedUser(user);
    } else {
      const newUser = Object.assign({}, user);
      const newArray = [newUser, ...messageUsers];
      setPage(1);
      await searchMessages(user?.volunteer?.id, 1);
      dispatch(setMessageUsers(newArray));
      setSelectedUser(user);
    }
    setNewMessageModal(false);
    if (isMobile) {
      setMessageScreen('message');
    }
  };

  const handleReadUserMessages = async (user: any) => {
    await readMessages(user?.volunteer?.id);
  };
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  return (
    <div className="container__dashboard-page">
      {!isMobile ? (
        <div>
          <TabsUnstyled
            value={tab}
            onChange={(event: React.SyntheticEvent, newValue: any) =>
              navigate(`/dashboard/${newValue}`)
            }
          >
            <TabsList>
              {displayTabs.map((tab: any) => (
                <Tab
                  key={tab.link}
                  value={tab.link}
                  sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                  {...a11yProps(0)}
                >
                  <div className="dashboard-tab-icon-label" key={tab.link}>
                    {displayIcon(tab.label)} {tab.label}
                    {tab.label === 'Bookings'
                      ? bookingsBadge(upcomingCallsAndGroupEvents)
                      : null}{' '}
                    {tab.label === 'Requests'
                      ? bookingsBadge(boostCallEventsPending)
                      : null}{' '}
                  </div>
                </Tab>
              ))}
            </TabsList>
            <TabPanel value={tab} key={0} />
            <TabPanel value={tab} key={1} />
            <TabPanel value={tab} key={2} />
            <TabPanel value={tab} key={3} />
            <TabPanel value={tab} key={4} />
            <TabPanel value={tab} key={5} />
          </TabsUnstyled>
        </div>
      ) : null}

      {!isLoadingMessageUsers &&
      !isLoadingAvailableUsers &&
      hasFinishedFetchingProfileAndLocation ? (
        <div className="dashboard-messaging-container">
          <div className="dashboard-messaging-title-wrapper">
            <div className="dashboard-messaging-title-component">Messages</div>

            {((messageUsers && messageUsers.length > 0) ||
              (availableUsers && availableUsers.length > 0)) && (
              <div className="dashboard-messaging-input-wrapper">
                <button onClick={() => setNewMessageModal(true)}>
                  <img src="new-message.svg" alt="New message button" />
                </button>

                <div className="dashboard-messaging-input-container">
                  <input
                    placeholder="Search contacts"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />

                  {searchValue && searchValue.length > 0 && (
                    <div
                      className="dashboard-messaging-clear-button"
                      onClick={() => {
                        setSearchValue('');
                        debouncedSearch('');
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {(messageUsers && messageUsers.length > 0) ||
          (availableUsers && availableUsers.length > 0) ? (
            <div
              className="messaging-wrapper"
              style={{
                height:
                  !isMobile || messageScreen === 'message'
                    ? height - 170
                    : 'fit-content',
              }}
            >
              {((isMobile && messageScreen === 'preview') || !isMobile) && (
                <div id="leftPanelDiv" className="messaging-left-panel">
                  <InfiniteScroll
                    dataLength={messageUsers.length}
                    next={async () => {
                      await getMessageUsers(undefined, pageUsers + 1);
                      setPageUsers(pageUsers + 1);
                    }}
                    hasMore={hasNextUsers}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="leftPanelDiv"
                  >
                    {messageUsers?.map((user: any, idx: number) => (
                      <MessagePreview
                        key={idx}
                        id={user?.volunteer?.id}
                        profilePicture={user?.volunteer?.profile_picture}
                        firstName={user?.volunteer?.first_name}
                        messagingCentreMessages={messagingCentreMessages}
                        clickMessage={async () => {
                          setPage(1);
                          searchMessages(user?.volunteer?.id, 1);
                          setSelectedUser(user);
                          await handleReadUserMessages(user);
                          await getMessageUsers();
                          if (isMobile) {
                            setMessageScreen('message');
                          }
                        }}
                        isSelected={
                          selectedUser &&
                          selectedUser?.volunteer?.id === user?.volunteer?.id
                        }
                        clickBlock={() => {
                          setSelectedUser(user);
                          setBlockMessageModal(true);
                        }}
                        blocked={user?.is_organisation_active}
                        unreadMessages={user?.organisation_new_messages_count}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}

              {((isMobile && messageScreen === 'message') || !isMobile) && (
                <div className="messaging-right-panel">
                  {!selectedUser ? (
                    <UnselectedMessage
                      onClickButton={() => setNewMessageModal(true)}
                    />
                  ) : (
                    <MessageCenter
                      refetchMessage={refetchMessage}
                      info={selectedUser}
                      accessPaused={organisationProfile?.is_access_paused}
                      clickBlock={() => setBlockMessageModal(true)}
                      sendMessage={handleSendMessage}
                      messages={messagingCentreMessages}
                      messagesTotal={messagesTotal}
                      hasNextMessages={hasNextMessages}
                      isMobile={isMobile}
                      clickBack={() => {
                        setSelectedUser(undefined);
                        setMessageScreen('preview');
                      }}
                      handleGetNextMessages={() => {
                        setPage(page + 1);
                        searchMessages(selectedUser?.volunteer?.id, page + 1);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <NoMessages />
          )}
        </div>
      ) : (
        <FullScreenLoading />
      )}

      <NewMessageModal
        open={newMessageModal}
        handleClose={() => setNewMessageModal(false)}
        availableUsers={availableUsers}
        handleAddMessageUser={handleAddMessageUser}
        getAvailableUsers={getAvailableUsers}
        hasNextAvailableUsers={hasNextAvailableUsers}
        handleGetNextAvailableUsers={() => {
          setPageNewUsers(pageNewUsers + 1);
          getAvailableUsers(undefined, pageNewUsers + 1);
        }}
      />
      <BlockMessageModal
        open={blockMessageModal}
        handleClose={() => setBlockMessageModal(false)}
        selectedUser={selectedUser}
        blocked={selectedUser?.is_organisation_active}
        handleBlock={() => {
          handleClickBlock();
          setBlockMessageModal(false);
        }}
      />
    </div>
  );
};

export default MessagingCentre;
