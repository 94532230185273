import { FormControl, NativeSelect } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { roundToNextWhole } from 'utils/text';
import ChartDumpComponent from './ChartDumbComponent';

interface Props {
  totalSessions: any[];
}

export default function NumberOfSessionsChart({ totalSessions }: Props) {
  var year = moment().year();
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [displayData, setDisplayData] = useState<any>([]);
  const [statsNumber, setTotalSessionsNumber] = useState(0);
  const [statsThisMonth, setSessionsThisMonth] = useState(0);

  useEffect(() => {
    const newData = [
      {
        name: 'J',
        sessions: 0,
        month: 1,
      },
      {
        name: 'F',
        sessions: 0,
        month: 2,
      },
      {
        name: 'M',
        sessions: 0,
        month: 3,
      },
      {
        name: 'A',
        sessions: 0,
        month: 4,
      },
      {
        name: 'M',
        sessions: 0,
        month: 5,
      },
      {
        name: 'J',
        sessions: 0,
        month: 6,
      },
      {
        name: 'J',
        sessions: 0,
        month: 7,
      },
      {
        name: 'A',
        sessions: 0,
        month: 8,
      },
      {
        name: 'S',
        sessions: 0,
        month: 9,
      },
      {
        name: 'O',
        sessions: 0,
        month: 10,
      },
      {
        name: 'N',
        sessions: 0,
        month: 11,
      },
      {
        name: 'D',
        sessions: 0,
        month: 12,
      },
    ];
    const d = new Date();
    let month = d.getMonth() + 1;
    const updateData = () => {
      totalSessions
        .filter((item) => Number(item.year) === Number(selectedYear))
        .forEach(function (item, index) {
          var result = newData.find(
            (dataEntry: { month: number }) => dataEntry.month === item.month
          );
          if (result)
            result.sessions =
              item.duration < 60 ? 1 : roundToNextWhole(item.duration / 60);
        });
      setTotalSessionsNumber(
        newData.reduce((n: any, { sessions }: any) => n + sessions, 0)
      );
      const sessions = newData.find(
        (dataEntry: { month: number }) => dataEntry.month === month
      )?.sessions;
      setSessionsThisMonth(sessions ? sessions : 0);
      setDisplayData(newData);
    };
    updateData();
  }, [selectedYear, totalSessions]);

  var yearsToDisplay = [];
  for (var i = 1; i < year - 2018; i++) yearsToDisplay.push(2019 + i);

  const handleChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  return (
    <div className="chart-paper-box">
      <FormControl sx={{ alignItems: 'flex-end', width: '100%' }}>
        <NativeSelect
          onChange={handleChange}
          defaultValue={selectedYear}
          variant="standard"
          disableUnderline
          inputProps={{
            id: 'uncontrolled-native',
          }}
          sx={{
            fontSize: 'Gilroy',
            fontWeight: 'bold',
            color: '#2f80ed',
            textTransform: 'none',
          }}
        >
          {yearsToDisplay.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <ChartDumpComponent
        data={displayData}
        statsNumber={statsNumber}
        statsThisMonth={statsThisMonth}
        chartText={'sessions'}
      />
    </div>
  );
}
