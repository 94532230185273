import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { IconButton, InputBase, SelectChangeEvent } from '@mui/material';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import SearchIcon from '@mui/icons-material/Search';
import loadingGif from './db-spinner.gif';
import whitelabelLoadingGif from './whitelabel-spinner.gif';
import './style.scss';
import {
  DashboardFilledButton,
  StyledDownloadButton,
  StyledTrashButton,
} from 'styling/buttons';
import { Tab, TabPanel, TabsList } from 'styling/tabs';
import { useSystemTogglesContext } from 'context/SystemToggles';
import PageNotFound from 'pages/PageNotFound';
import {
  useGetAttachmentStorage,
  searchAttachments,
  sendAttachment,
  useGetAttachments,
  deleteAttachment,
  sortAttachments,
} from 'api/attachments';
import { Attachment } from 'types';
import { displayIcon, tabs } from 'pages/Dashboard/utilities';
import {
  selectAttachments,
  updateAttachments,
} from 'features/reduxStore/attachments/attachments';
import useRedux from 'hooks/useRedux';
import FileUploadPopup from './fileUploadPopup';
import LibrarySort from './librarySort';
import FileUploadComponent from './fileUpload';
import FilterFiles from './filterFiles';
import {
  StyledBox,
  BorderLinearProgress,
  StyledPaper,
} from './progressBarComponent';
import useWindowSize from 'hooks/useWindowSize';
import DeleteFilePopup from './deleteFilePopup';
import NoFilesMessage from './noFilesMessage';
import { selectPartner } from 'features/partner';
import FileErrorPopup from './fileErrorPopup';
import LoadingSplashPopup from 'pages/LoadingSplash';
import apiInstance from 'api/config';

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const formatDate = (dateString: string) => {
  const date = moment(dateString);

  const formattedDate = date.format('DD-MM-YYYY');
  return formattedDate;
};

const Library = () => {
  const { toggles } = useSystemTogglesContext();
  const { useSelector, dispatch } = useRedux();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const partner = useSelector(selectPartner);
  const [word, setWord] = useState<string>('');
  const [counter, setCounter] = useState<number>(19);
  const [displayFiles, setDisplayFiles] = useState<any[]>([]);
  const [sort, setSort] = useState<string>('desc');
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupErrorOpen, setPopupErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [nothingFound, setNothingFound] = useState<boolean>(false);

  const [fileDeletePopupOpen, setFileDeletePopupOpen] = useState(false);
  const [checked, setChecked] = useState<string | null>(null);
  const [deleteFile, setDeleteFile] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const showAttachmentCenter = toggles['ATTACHMENT_CENTRE'];
  const filesFromRedux = useSelector(selectAttachments);
  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleCloseErrorPopup = () => {
    setPopupErrorOpen(false);
  };
  const handleCloseDeletePopup = () => {
    setFileDeletePopupOpen(false);
  };
  const {
    attachments,
    hasFinishedLoadingAttachments,
    count,
    next,
    refetchAttachments,
  } = useGetAttachments(counter);
  const truncateFileName = (word: string) => {
    if (word.length > 15) {
      return word.slice(0, 10) + '...' + word.slice(-3);
    }
    return word;
  };
  useEffect(() => {
    dispatch(
      updateAttachments({ attachments: attachments, count: count, next: next })
    );
  }, [attachments, count, next, dispatch]);

  useEffect(() => {
    if (filesFromRedux.attachments)
      setDisplayFiles(filesFromRedux.attachments?.slice(0, counter));
  }, [filesFromRedux, counter]);

  const loadMoreAttachments = () => {
    setCounter((prevCounter) => prevCounter + 19);
    refetchAttachments(counter + 19, 0);
  };

  const { storage, refetchStorage, hasFinishedLoadingStorage } =
    useGetAttachmentStorage();

  useEffect(() => {
    refetchStorage();
  }, [count, refetchStorage]);

  const handleDeleteSelectedFile = async (file: Attachment) => {
    await deleteAttachment(file);
    await refetchAttachments(counter, 0);
    handleCloseDeletePopup();
  };
  const handleSearch = async () => {
    try {
      const response = await searchAttachments(
        word,
        counter,
        sort,
        checked ? checked : undefined
      );
      const fetchedFiles = response.attachments;
      if (fetchedFiles.length === 0) setNothingFound(true);
      dispatch(
        updateAttachments({
          attachments: fetchedFiles,
          count: count,
          next: next,
        })
      );
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  };

  const tab = '';
  const handleInputChange = (event: { target: { value: any } }) => {
    setNothingFound(false);
    const newValue = event.target.value;
    setWord(newValue);
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event?.target?.files;
    if (selectedFiles) {
      setIsLoading(true); // Start loading
      try {
        const result = await sendAttachment(selectedFiles);
        if (result.success === false) {
          setErrorMessage(result.error);
          setPopupErrorOpen(true);
        } else {
          await refetchAttachments(counter, 0);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setIsLoading(false); // End loading
      }
    }
  };

  const handleSort = async (event: SelectChangeEvent) => {
    setWord('');
    setNothingFound(false);
    setSort(event.target.value as string);
  };
  const handleToggle = (value: string | null) => {
    setWord('');
    setNothingFound(false);

    if (checked === value) {
      setChecked(null);
    } else {
      setChecked(value);
    }
  };
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  useEffect(() => {
    const handleSortFilter = async (
      sort: string | undefined,
      checked: string | null | undefined
    ) => {
      let response = await sortAttachments(
        counter,
        sort,
        checked ? checked : undefined
      );
      const fetchedFiles = response?.attachments;
      if (fetchedFiles)
        dispatch(
          updateAttachments({
            attachments: fetchedFiles,
            count: response.count,
          })
        );
    };
    handleSortFilter(sort, checked);
  }, [checked, sort, dispatch, counter]); // Depend on `checked` state.
  const isScreen = width > 1200;
  if (!hasFinishedLoadingAttachments || !hasFinishedLoadingStorage)
    return <LoadingSplashPopup open={!hasFinishedLoadingAttachments} />;

  const getFileInfo = async (filePath: string) => {
    return await apiInstance
      .get(`api/attachment/attachment/get?path=${filePath}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        const element = document.createElement('a');
        const file = new Blob([response.data], {
          type: contentType,
        });
        element.href = URL.createObjectURL(file);
        element.download = filePath;
        document.body.appendChild(element);
        element.click();
      });
  };

  const handleDownload = async (file: {
    file_info: string;
    file_path: string;
  }) => {
    if (file.file_info) {
      const element = document.createElement('a');
      element.href = file.file_info;
      element.target = '_blank';
      element.rel = 'noopener noreferrer';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else await getFileInfo(file.file_path);
  };

  if (isLoading)
    return (
      <LoadingSplashPopup
        open={isLoading}
        message="Your file is being uploaded"
      />
    );
  if (showAttachmentCenter)
    return (
      <div className="library-container">
        <div className="container__dashboard-page">
          {isScreen ? (
            <div>
              <TabsUnstyled
                value={tab}
                onChange={(event: React.SyntheticEvent, newValue: any) =>
                  navigate(`/dashboard/${newValue}`)
                }
              >
                <TabsList>
                  {displayTabs.map((tab: any) => (
                    <Tab
                      key={tab.link}
                      value={tab.link}
                      sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                      {...a11yProps(0)}
                    >
                      <div className="dashboard-tab-icon-label" key={tab.link}>
                        {displayIcon(tab.label)} {tab.label}
                      </div>
                    </Tab>
                  ))}
                </TabsList>
                <TabPanel value={tab} key={0} />
                <TabPanel value={tab} key={1} />
                <TabPanel value={tab} key={2} />
                <TabPanel value={tab} key={3} />
                <TabPanel value={tab} key={4} />
                <TabPanel value={tab} key={5} />
              </TabsUnstyled>
            </div>
          ) : null}
          <div className="library-container-info">
            <div className="library-header">
              <div className="library-header-text">My Library</div>
              <StyledPaper
                as="form"
                onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <InputBase
                  sx={{ flex: 1, paddingLeft: '10px' }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={word}
                  onChange={handleInputChange}
                />
                <IconButton onClick={handleSearch}>
                  <SearchIcon sx={{ color: 'primary' }} fontSize="small" />
                </IconButton>
              </StyledPaper>
              <div className="filter-sort-functions">
                <LibrarySort handleSort={handleSort} sort={sort} />
                <FilterFiles
                  files={filesFromRedux.attachments}
                  onFilterChange={handleToggle}
                  checked={checked}
                />
              </div>

              <StyledBox>
                {storage?.available_space < 10 ? (
                  <div className="storage-alarm">
                    Storage space is running out, delete some files
                  </div>
                ) : null}

                <div className="progress-text">
                  {' '}
                  {storage?.available_space} Mb available
                </div>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    storage
                      ? (storage?.used_space / storage?.allowed_space) * 100
                      : 0
                  }
                />
              </StyledBox>
            </div>
            {hasFinishedLoadingAttachments && count && count > 0 ? (
              <div className="info-text-library">
                A place where you can store all your documents and notes from
                your mentoring sessions 💪 You will be able to manage all the
                files received here.
              </div>
            ) : null}
            {hasFinishedLoadingAttachments && count && count > 0 ? (
              <div className="files-container">
                <FileUploadComponent
                  handleFileUpload={handleFileUpload}
                  setPopupErrorOpen={setPopupErrorOpen}
                  setErrorMessage={setErrorMessage}
                  maxSize={storage?.available_space}
                />
                <label htmlFor="fileInput">
                  <div className="add-file-container">
                    <img
                      className="plus-icon-add-file"
                      src={'/plus-icon.svg'}
                      alt={'add file'}
                      height="50px"
                      width="50px"
                    />
                    You can upload a file up to 10MB
                  </div>
                </label>
                {displayFiles &&
                  displayFiles.map((file) => (
                    <div className="file-container">
                      <div className="text-file-format">
                        {truncateFileName(file.name)}
                      </div>
                      <div className="text-file-format">
                        {' '}
                        {file.size < 1
                          ? `${file.size * 1000} Kb`
                          : `${file.size} MB`}{' '}
                      </div>
                      <div className="date-file-format">
                        {file.created_by === file.owner
                          ? 'uploaded on'
                          : 'received on'}{' '}
                        {formatDate(file.created)}
                      </div>
                      <div className="file-actions">
                        <StyledDownloadButton
                          onClick={() => handleDownload(file)}
                        />
                        <StyledTrashButton
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setFileDeletePopupOpen(true);
                            setDeleteFile(file);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                {nothingFound ? (
                  <div className="no-search-results">
                    Sorry, we couldn't find any results
                    <img
                      src="/no-experts.png"
                      alt="no results available"
                      height="100%"
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="no-files-container">
                <FileUploadComponent
                  handleFileUpload={handleFileUpload}
                  setPopupErrorOpen={setPopupErrorOpen}
                  setErrorMessage={setErrorMessage}
                  maxSize={storage?.available_space}
                />
                <label htmlFor="fileInput">
                  <div className="add-file-container">
                    {isLoading ? (
                      <img
                        height="50px"
                        src={
                          partner && partner.white_label
                            ? whitelabelLoadingGif
                            : loadingGif
                        }
                        alt="Uploading document loading spinner"
                      />
                    ) : (
                      <img
                        className="plus-icon-add-file"
                        src={'/plus-icon.svg'}
                        alt={'add file'}
                        height="50px"
                        width="50px"
                      />
                    )}
                  </div>
                </label>
                <NoFilesMessage />
              </div>
            )}

            {filesFromRedux.count > counter ||
            filesFromRedux.attachments?.length > counter ? (
              <div className="loading-attachments-button">
                <DashboardFilledButton
                  sx={{ width: '263px', alignSelf: 'center' }}
                  variant="contained"
                  onClick={() => loadMoreAttachments()}
                >
                  Load more
                </DashboardFilledButton>
              </div>
            ) : null}
            <FileUploadPopup open={popupOpen} onClose={handleClosePopup} />
            <FileErrorPopup
              open={popupErrorOpen}
              onClose={handleCloseErrorPopup}
              message={errorMessage}
            />
            <DeleteFilePopup
              open={fileDeletePopupOpen}
              onClose={handleCloseDeletePopup}
              handleDeleteSelectedFile={handleDeleteSelectedFile}
              deleteFile={deleteFile}
            />
          </div>
        </div>
      </div>
    );
  else return <PageNotFound />;
};

export default Library;
