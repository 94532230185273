import React, { useState, useEffect } from 'react';
import { getUrlParam } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import useGetBoostCallEvent from 'apiHooks/boostCalls/useGetBoostCallEvent';

import { displayIcon, tabs } from 'pages/Dashboard/utilities';
import ManageCallPage from './manageCallPage';
import './style.scss';
import useGetBoostCallEventRequest from 'apiHooks/boostCallRequests/useGetBoostCallEventRequest';
import NewCallDetails from './newCallDetails';
import PageNotFound from 'pages/PageNotFound';
import FullScreenLoading from 'components/FullScreenLoading';
import useWindowSize from 'hooks/useWindowSize';
import { Tab, TabPanel, TabsList } from 'styling/tabs';
import { useSystemTogglesContext } from 'context/SystemToggles';

const NewManageCallDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();

  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);

  const tab = '';
  let navigate = useNavigate();
  const id = (getUrlParam('cid') as string) || (getUrlParam('id') as string);
  const type = getUrlParam('type') as string;
  const isBoostCall = type === 'events';
  const isBoostCallRequest = type === 'requests';
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'My Action Plans');
      setDisplayTabs(updatedTabs);
    } else setDisplayTabs(tabs);
  }, [showActionPlan]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // Get boostcall event
  const { boostCallEvent, isLoadingBoostCallEvent } = useGetBoostCallEvent({
    id: isBoostCall ? id : null,
  });
  const { boostCallEventRequest, isLoadingBoostCallEventRequest } =
    useGetBoostCallEventRequest({
      id: isBoostCallRequest ? id : null,
    });
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  if (isLoading || isLoadingBoostCallEvent || isLoadingBoostCallEventRequest)
    return <FullScreenLoading />;
  else if (boostCallEvent || boostCallEventRequest)
    return (
      <>
        {isMobile ? (
          <div className="dashboard-container-info">
            {isBoostCallRequest && boostCallEventRequest ? (
              <NewCallDetails event={boostCallEventRequest} />
            ) : null}
            {isBoostCall && boostCallEvent ? (
              <ManageCallPage event={boostCallEvent} />
            ) : null}
          </div>
        ) : (
          <div className="container__dashboard-page">
            <div>
              <TabsUnstyled
                value={tab}
                onChange={(event: React.SyntheticEvent, newValue: any) =>
                  navigate(`/dashboard/${newValue}`)
                }
              >
                <TabsList>
                  {displayTabs.map((tab: any) => (
                    <Tab
                      key={tab.link}
                      value={tab.link}
                      sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                      {...a11yProps(0)}
                    >
                      <div className="dashboard-tab-icon-label" key={tab.link}>
                        {displayIcon(tab.label)} {tab.label}
                      </div>
                    </Tab>
                  ))}
                </TabsList>
                <TabPanel value={tab} key={0} />
                <TabPanel value={tab} key={1} />
                <TabPanel value={tab} key={2} />
                <TabPanel value={tab} key={3} />
                <TabPanel value={tab} key={4} />
                <TabPanel value={tab} key={5} />
              </TabsUnstyled>
            </div>
            <div className="dashboard-container-info">
              {isBoostCallRequest && boostCallEventRequest ? (
                <NewCallDetails event={boostCallEventRequest} />
              ) : null}
              {isBoostCall && boostCallEvent ? (
                <ManageCallPage event={boostCallEvent} />
              ) : null}
            </div>
          </div>
        )}
      </>
    );
  else return <PageNotFound />;
};

export default NewManageCallDetails;
