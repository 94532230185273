import React, { useRef, useState, useEffect } from 'react';
import NewRecommendationCard from '../NewRecommendationCard';
import './styling/recommendation.scss';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NewRecommendationMentorCard from '../NewRecommendationMentorCard';
import useWindowSize from 'hooks/useWindowSize';
import RecommendationEmptyPage from './recommendationEmptyPage';
import apiInstance from 'api/config';

interface Props {
  events: any;
  mentors: any;
  timeZoneString?: string;
  refetchRecommendedData: any;
  hideGroupEvents: boolean;
}

const Recommendation = ({
  events,
  mentors,
  timeZoneString = '',
  hideGroupEvents,
  refetchRecommendedData,
}: Props) => {
  const { width } = useWindowSize();
  const breakpoint = 1220;

  const sliderRef = useRef<any>(null);
  const sliderMentorsRef = useRef<any>(null);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1121,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };
  const settingsMentors = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1121,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const [eventsIndex, setEventsIndex] = useState(0);
  const [mentorsIndex, setMentorsIndex] = useState(0);
  const [sliderBlock, setSliderBlock] = useState(false);

  useEffect(() => {
    if (sliderBlock) {
      setTimeout(() => {
        setSliderBlock(false);
      }, 800);
    }
  }, [sliderBlock]);

  const handleEventsNext = () => {
    if (eventsIndex < events.length - 1) {
      sliderRef.current?.slickNext();
      setEventsIndex(eventsIndex + 1);
      setSliderBlock(true);
    }
  };

  const handleEventsPrev = () => {
    if (eventsIndex > 0) {
      sliderRef.current?.slickPrev();
      setEventsIndex(eventsIndex - 1);
      setSliderBlock(true);
    }
  };

  const handleMentorsNext = () => {
    if (mentorsIndex < mentors.length - 1) {
      sliderMentorsRef.current?.slickNext();
      setMentorsIndex(mentorsIndex + 1);
      setSliderBlock(true);
    }
  };

  const handleMentorsPrev = () => {
    if (mentorsIndex > 0) {
      sliderMentorsRef.current?.slickPrev();
      setMentorsIndex(mentorsIndex - 1);
      setSliderBlock(true);
    }
  };

  const eventsPreviousDisabled = eventsIndex === 0;
  const eventsNextDisabled =
    width > breakpoint
      ? eventsIndex === events.length - 2
      : eventsIndex === events.length - 1;

  const mentorsPreviousDisabled = mentorsIndex === 0;
  const mentorsNextDisabled =
    width > breakpoint
      ? mentorsIndex === mentors.length - 2
      : mentorsIndex === mentors.length - 1;

  const handleHideRecommendation = async (recommendedId: any) => {
    if (recommendedId) {
      await apiInstance.patch(
        `api/recommendation/recommendedvolunteers/${recommendedId}/`,
        {
          hidden_by_user: true,
        }
      );
    }
    refetchRecommendedData();
  };

  return (
    <div className="dashboard-actions-container">
      {events.length > 0 || mentors.length > 0 ? (
        <div>
          <div className="recommendation-title-component">Recommendations</div>
          {hideGroupEvents === false ? (
            <div className="recommendation-header">
              <div className="recommendation-text-wrapper">
                <div className="recommendation-section-title">Events</div>
                <div className="recommendation-section-subtitle">
                  We think you might be interested in these workshops or
                  masterclasses
                </div>
              </div>

              {events.length > 1 && (
                <div className="recommendation-buttons-wrapper">
                  <img
                    src={
                      eventsPreviousDisabled
                        ? '/arrow-left-inactive.svg'
                        : '/arrow-left-active.svg'
                    }
                    onClick={
                      !eventsPreviousDisabled && !sliderBlock
                        ? handleEventsPrev
                        : undefined
                    }
                    alt="left arrow button"
                  />
                  <img
                    src={
                      eventsNextDisabled
                        ? '/arrow-right-inactive.svg'
                        : '/arrow-right-active.svg'
                    }
                    onClick={
                      !eventsNextDisabled && !sliderBlock
                        ? handleEventsNext
                        : undefined
                    }
                    alt="right arrow button"
                  />
                </div>
              )}
            </div>
          ) : null}

          {hideGroupEvents === false ? (
            <div className="recommendation-slider-relative">
              <div className="recommendation-slider-absolute">
                <Slider {...settings} ref={sliderRef}>
                  {events?.map((event: any) => (
                    <NewRecommendationCard
                      appointmentData={event}
                      timeZone={timeZoneString}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          ) : null}

          <div className="recommendation-header recommendation-header-margin">
            <div className="recommendation-text-wrapper">
              <div className="recommendation-section-title">Mentors</div>
              <div className="recommendation-section-subtitle">
                Unlock new potential with these mentors
              </div>
            </div>

            {mentors.length > 1 && (
              <div className="recommendation-buttons-wrapper">
                <img
                  src={
                    mentorsPreviousDisabled
                      ? '/arrow-left-inactive.svg'
                      : '/arrow-left-active.svg'
                  }
                  onClick={
                    !mentorsPreviousDisabled && !sliderBlock
                      ? handleMentorsPrev
                      : undefined
                  }
                  alt="left arrow button"
                />
                <img
                  src={
                    mentorsNextDisabled
                      ? '/arrow-right-inactive.svg'
                      : '/arrow-right-active.svg'
                  }
                  onClick={
                    !mentorsNextDisabled && !sliderBlock
                      ? handleMentorsNext
                      : undefined
                  }
                  alt="right arrow button"
                />
              </div>
            )}
          </div>

          <div className="recommendation-slider-relative">
            <div className="recommendation-slider-absolute">
              <Slider {...settingsMentors} ref={sliderMentorsRef}>
                {mentors.length > 0 ? (
                  mentors?.map((mentor: any) => (
                    <NewRecommendationMentorCard
                      mentorData={mentor}
                      clickHide={handleHideRecommendation}
                    />
                  ))
                ) : (
                  <div className="impact-help-label">
                    We will recommend mentors when you set your skills
                    preferences{' '}
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <RecommendationEmptyPage />
      )}
    </div>
  );
};

export default Recommendation;
