import React from 'react';
import FullScreenLoading from './components/FullScreenLoading';
import { SystemToggleContext } from './context/SystemToggles';
import useGetSystemToggles from './apiHooks/useGetSystemToggles';
import useSelectProfileData from './hooks/useSelectProfileData';
import useLogRocket from './hooks/useLogRocket';
import useUpdateTimezone from './features/userLocation/hooks/useUpdateTimeZone';
import useAuth from './features/auth/hooks/useAuth';
import { getAccessTokenCookie, getRefreshTokenCookie } from 'utils/auth';
import {
  redirectToF4S,
  useRedirectToPartnerWebsite,
} from 'utils/partnerFunctionality';

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  redirectToF4S();
  useRedirectToPartnerWebsite();

  // running this on every page means that refreshAuthLogic & redirectProtecedRoutesToLogin are used to auth-protect the correct pages
  const { userProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData({
      autoFetch: !!getAccessTokenCookie() || !!getRefreshTokenCookie(),
    });
  const { toggles, hasFinishedLoadingToggles } = useGetSystemToggles({
    isBetaUser: userProfile?.is_beta_user,
  });
  const autoDetectTimezone = toggles['TIMEZONE_AUTO_DETECT'];
  useUpdateTimezone({
    autoFetch:
      !!autoDetectTimezone &&
      (!!getAccessTokenCookie() || !!getRefreshTokenCookie()),
  });
  useLogRocket();
  useAuth();

  return (
    <SystemToggleContext.Provider
      value={{ toggles, hasFinishedLoadingToggles }}
    >
      <>
        {(getAccessTokenCookie() || getRefreshTokenCookie()) &&
        !hasFinishedFetchingProfileAndLocation ? (
          <FullScreenLoading />
        ) : (
          children
        )}
      </>
    </SystemToggleContext.Provider>
  );
};

export default AppProvider;
